import React, { useEffect, useState, useCallback } from "react";
import { State } from "../../../store";
import { selectActiveCompany } from "../../../ducks/active-company/selectors";
import { actions as activeCompanyActions } from "../../../ducks/active-company";
import { selectCompanies } from "@kvittoapp/ducks/dist/companies/selectors";
import * as companiesActions from "@kvittoapp/ducks/dist/companies";
import { connect, ConnectedProps } from "react-redux";
import { Company } from "@kvittoapp/ducks/dist/company/models";
import { PageLayout } from "../../../components";
import { Paper, Box, Typography } from "@material-ui/core";
import styles from "./ActiveCompany.module.css";
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state: State) => ({
  activeCompany: selectActiveCompany(state),
  companies: selectCompanies(state),
});

const mapDispatchToProps = {
  setActiveCompany: activeCompanyActions.setActiveCompany,
  getCompanies: companiesActions.getCompanies,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface ActiveCompanyProps extends PropsFromRedux {
  redirectPath: string;
}

const ActiveCompany = ({
  activeCompany,
  companies,
  redirectPath,
  setActiveCompany,
  getCompanies,
}: ActiveCompanyProps) => {
  const [hasSelectedCompany, setHasSelectedCompany] = useState(false);
  const setActive = useCallback(
    (companyId: string) => {
      setActiveCompany(companyId);
      setHasSelectedCompany(true);
    },
    [setActiveCompany, setHasSelectedCompany]
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (companies.length === 1) {
      setActive(companies[0].id);
    }
  }, [companies, setActive]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  if (hasSelectedCompany) {
    navigate(redirectPath);
  }
  if (companies.length === 0) {
    return (
      <PageLayout title="Företagskoppling saknas">
        <Box>
          Du är inte kopplad till något företag. Kontakta din
          företagsrepresentant för åtgärd.
        </Box>
      </PageLayout>
    );
  }

  return (
    <PageLayout title="Välj företag">
      <Box paddingBottom={5}>
        Du har koppling till flera företag. Välj vilket du vill vara inloggad
        för. Du kan byta företag via inställningar.
      </Box>
      {[...companies]
        .filter((company) => company.enabled)
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((company) => (
          <Row
            company={company}
            selected={company.id === activeCompany}
            key={company.id}
            onClick={() => setActive(company.id)}
          />
        ))}
    </PageLayout>
  );
};

interface RowProps {
  company: Company;
  selected: boolean;
  onClick: () => void;
}
const Row = ({ company, selected, onClick }: RowProps) => {
  return (
    <Box paddingBottom={3} className={styles.row}>
      <Paper elevation={2} onClick={onClick}>
        <Box
          display="flex"
          padding={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            color={selected ? "primary" : "inherit"}
          >
            {company.name}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default connector(ActiveCompany);
