import axios from "axios";
var setBaseUrl = function (baseURL) { return (axios.defaults.baseURL = baseURL); };
var createIntegrationActivation = function (authorizationCode) {
    return axios.post("/api/v1/integration/reactivation", {
        authorizationCode: authorizationCode,
    });
};
var registerCompany = function (email, password, accountant, authorizationCode) {
    return axios.post("/api/v1/companies", {
        email: email,
        password: password,
        accountant: accountant,
        authorizationCode: authorizationCode,
    });
};
var getCompanies = function () { return axios.get("/api/v1/companies"); };
var getCompany = function (companyId) {
    return axios.get("/api/v1/companies/".concat(companyId));
};
var updateCompany = function (companyId, params) {
    return axios.put("/api/v1/companies/".concat(companyId), params);
};
var deleteCompany = function (companyId) {
    return axios.delete("/api/v1/companies/".concat(companyId));
};
var getCostCenters = function (companyId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/cost-centers"), {});
};
var getProjects = function (companyId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/projects"), {});
};
var updateIntegrationTokens = function (companyId) {
    return axios.post("/api/v1/companies/".concat(companyId, "/integration-tokens"), {});
};
var getVoucherSeries = function (companyId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/voucher-series"), {});
};
var getIntegrationStatus = function (companyId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/integration-status"), {});
};
var createReceipt = function (companyId, userId, params) {
    return axios.post("/api/v1/companies/".concat(companyId, "/users/").concat(userId, "/receipts"), params, {});
};
var createFile = function (companyId, file, onUploadProgress) {
    return axios.post("/api/v1/companies/".concat(companyId, "/files"), file, {
        onUploadProgress: onUploadProgress,
    });
};
var deleteFile = function (companyId, fileId) {
    return axios.delete("/api/v1/companies/".concat(companyId, "/files/").concat(fileId), {});
};
var getFile = function (companyId, fileId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/files/").concat(fileId), {
        responseType: "blob",
    });
};
var getVouchers = function (companyId, userId) {
    if (userId) {
        return axios.get("/api/v1/companies/".concat(companyId, "/users/").concat(userId, "/receipts"));
    }
    return axios.get("/api/v1/companies/".concat(companyId, "/receipts"));
};
var createCostType = function (companyId, params) {
    return axios.post("/api/v1/companies/".concat(companyId, "/cost-types"), params);
};
var getCostTypes = function (companyId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/cost-types"));
};
var updateCostType = function (companyId, costTypeId, params) {
    return axios.put("/api/v1/companies/".concat(companyId, "/cost-types/").concat(costTypeId), {
        description: params.description,
        vat: params.vat,
        enabled: params.enabled,
    });
};
var getUser = function (userId) { return axios.get("/api/v1/users/".concat(userId)); };
var getCompanyUsers = function (companyId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/users"));
};
var createUser = function (companyId, params) {
    return axios.post("/api/v1/companies/".concat(companyId, "/users"), params);
};
var updateUser = function (userId, params) {
    return axios.put("/api/v1/users/".concat(userId), params);
};
var updateUserCompanyAccess = function (userId, companyId, params) { return axios.put("/api/v1/companies/".concat(companyId, "/users/").concat(userId), params); };
var getPaymentTypes = function (companyId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/payment-types"));
};
var createPaymentType = function (companyId, params) {
    return axios.post("/api/v1/companies/".concat(companyId, "/payment-types"), params);
};
var updatePaymentType = function (companyId, paymentTypeId, params) {
    return axios.put("/api/v1/companies/".concat(companyId, "/payment-types/").concat(paymentTypeId), {
        description: params.description,
        enabled: params.enabled,
    });
};
var getAccount = function (companyId, accountNumber) {
    return axios.get("/api/v1/companies/".concat(companyId, "/accounts/").concat(accountNumber));
};
var getLockedPeriod = function (companyId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/locked-period"));
};
var getCompanyInformation = function (companyId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/information"));
};
var getUserSettings = function (companyId, userId) {
    return axios.get("/api/v1/companies/".concat(companyId, "/users/").concat(userId, "/settings"));
};
var updateUserSetting = function (companyId, userId, params) {
    return axios.post("/api/v1/companies/".concat(companyId, "/users/").concat(userId, "/settings"), params);
};
var requestResetPasswordToken = function (email) {
    return axios.post("/api/v1/users/reset-password", { email: email });
};
var resetPassword = function (token, password) {
    return axios.post("/api/v1/users/reset-password/".concat(token), { password: password });
};
var createAuth = function (email, password) {
    return axios.post("/api/v1/auth", { email: email, password: password });
};
var getAuth = function () { return axios.get("/api/v1/auth"); };
var deleteAuth = function () { return axios.delete("/api/v1/auth"); };
var sendReports = function () { return axios.post("/api/v1/reports"); };
var api = {
    setBaseUrl: setBaseUrl,
    createIntegrationActivation: createIntegrationActivation,
    getCompanies: getCompanies,
    registerCompany: registerCompany,
    updateCompany: updateCompany,
    deleteCompany: deleteCompany,
    getCompany: getCompany,
    getCostCenters: getCostCenters,
    getVoucherSeries: getVoucherSeries,
    createReceipt: createReceipt,
    getVouchers: getVouchers,
    createFile: createFile,
    deleteFile: deleteFile,
    getFile: getFile,
    createCostType: createCostType,
    getCostTypes: getCostTypes,
    updateCostType: updateCostType,
    getCompanyUsers: getCompanyUsers,
    createUser: createUser,
    updateUser: updateUser,
    getPaymentTypes: getPaymentTypes,
    createPaymentType: createPaymentType,
    updatePaymentType: updatePaymentType,
    getAccount: getAccount,
    getUserSettings: getUserSettings,
    updateUserSetting: updateUserSetting,
    requestResetPasswordToken: requestResetPasswordToken,
    resetPassword: resetPassword,
    getUser: getUser,
    createAuth: createAuth,
    getAuth: getAuth,
    deleteAuth: deleteAuth,
    sendReports: sendReports,
    updateUserCompanyAccess: updateUserCompanyAccess,
    getProjects: getProjects,
    updateIntegrationTokens: updateIntegrationTokens,
    getIntegrationStatus: getIntegrationStatus,
    getLockedPeriod: getLockedPeriod,
    getCompanyInformation: getCompanyInformation,
};
export default api;
